<template>
  <PromiseContainer :promises.sync="promises">
    <overview :items="getTeams" :headers="headers" :filters="filters" header-text="Teams" add-btn-text="Nieuw team" local-storage-prefix="team"
      :add-item-route="{ name: RouteNames.TEAM_CREATE }" 
      :detail-page-function="detailPageFunction"
      >
      <template v-slot:item.teamName="{ item }">
        <div class="d-flex flex-row">
          <avatar :initials="formatTeamToInitials(item)" />
          <div class="pl-2 align-self-center">{{ item.teamName }}</div>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="item.active ? 'green' : 'red'" label>
          {{ item.status }}
        </v-chip>
      </template>
    </overview>
  </PromiseContainer>
</template>

<script>
import updateTeamDialog from "@/components/teamoverview/dialog/UpdateTeamDialog";
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import { formatTeamToInitials } from "@/shared/utils/teamUtils";
import Overview from "@/components/shared/Overview";
import Avatar from "@/components/shared/Avatar";
import RouteNames from "@/router/RouteNames";

export default {
  name: "TeamOverview",
  components: {
    PromiseContainer,
    Overview,
    Avatar
  },
  data() {
    return {
      RouteNames,
      sortBy: null,
      searchValue: null,
      error: null,
      showFab: true,
      filters: [],
      // TODO: Replace updateTeamDialog with a dedicated edit view
      updateTeamComponent: updateTeamDialog,
      formatTeamToInitials: formatTeamToInitials,
      headers: [
        {
          text: 'Naam',
          value: 'teamName',
          cellClass: 'notranslate'
        },
      ],
      promises: [this.$store.dispatch('teamsModule/fetchNotArchivedTeamsOfOrganization'), this.$store.commit('teamsModule/setTeam', null)],
      detailPageFunction: (team) => {
        this.promises.push(
          this.$store.commit('teamsModule/setTeam', team),
          this.$store.dispatch('teamsModule/fetchUsersFromTeam', team.id),
          this.$store.dispatch('teamsModule/fetchLeadersFromTeam', team.id),
        )
        return {
          name: RouteNames.TEAM_DETAIL,
          params: {
            teamId: team.id
          }
        }
      },
      deleteAction: 'teamsModule/deleteTeam',
      deleteMessageFunction: (team) => `Klant ${team.teamName}`,
      toggleActiveAction: 'teamsModule/toggleTeamActive',
      toggleActivePayloadFunction: (team) => team
    }
  },
  created() {
    this.resetFilters()
  },
  methods: {
    setError(error) {
      this.error = error
    },
    toggleFab() {
      this.showFab = !this.showFab
    },
    resetFilters() {
      this.filters = [
        {
          title: 'Status',
          options: [
            {
              id: 'status#active',
              label: 'Actief',
              active: false,
              predicate: (team) => team.active,
            },
            {
              id: 'status#inactive',
              label: 'Inactief',
              active: false,
              predicate: (team) => !team.active,
            }
          ]
        },
      ]
    }
  },
  computed: {
    getTeams() {
      let teams = this.$store.state.teamsModule.teams
      teams = teams?.map(team => ({
        ...team,
        status: team.active ? 'actief' : 'inactief'
      }))
      return teams
    },

  },
}
</script>